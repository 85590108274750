import { Injectable, inject } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
  HttpInterceptorFn,
  HttpHandlerFn,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap, timeout } from 'rxjs/operators';
import { Globals } from '../globals';
import { Router } from '@angular/router';
import { CommonService } from '../services/common.service';

export const ApiInterceptor: HttpInterceptorFn = (
  request: HttpRequest<unknown>,
  next: HttpHandlerFn,
) => {
  const commonService = inject(CommonService);
  const globals = inject(Globals);
  const router = inject(Router);
  const url = request.url.split('/');
  const add_documents =
    url[url.length - 2] == (
      'add_health_sciences_documents' ||
      'add_transcripts_documents' ||
      'add_licenses_documents' ||
      'add_health_sciences_documents' ||
      'add-ceu-activity'
    ) ||
    url[url.length - 1] == (
      'add_fitness_response' || 'import-voucher-file' || 'exam-score-excel'
    );
  const hideResMsg = url[url.length - 1] == 'browser-logs';

  const token = commonService.getCookie('web_login_token');
  request = request.clone({
    setHeaders: {
      Authorization: `${token}`,
      Accept: request.method == 'POST' && add_documents ? 'multipart/form-data' : 'application/json',
    },
  });

  return next(request).pipe(
    map((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        // Handle response
      }
      return event;
    }),
    catchError((err: HttpErrorResponse) => {
      // Handle error
      if (hideResMsg) {
        return throwError(() => err);
      } else {
        if (err.status === 401 && (err.error.message == 'Unauthenticated.' || err.error.message == 'Unauthenticated')) {
          commonService.hideLoader();
          commonService.showCommonErrorNotification(
            'Your session has expired. Please log in again to continue using the app.'
          );
          setTimeout(() => {
            globals.authData = null;
            globals.isLoggedIn = false;
            commonService.clearLoginCookie();
            if (typeof window !== 'undefined') {
              window.location.href = globals.baseLoginUrl;
            }
          }, 2000);
        } else if (err.status === 429) {
          commonService.hideLoader();
          commonService.showCommonErrorNotification('Too many requests. Please try again later.');
        } else {
          commonService.hideLoader();
          commonService.showCommonErrorNotification();
        }
      }
      return throwError(() => err);
    })
  );
};