<header [ngClass]="{ header_impersonate: globals.impersonateUserId }">
  <div *ngIf="globals.impersonateUserId" class="header_impersonate_block">
    <p>
      You are impersonating "<b>{{ globals.impersonateUserName }}</b
      >"
    </p>
    <button><i class="fa-solid fa-stop"></i> Stop</button>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-9 col-sm-6 col-md-7 col-lg-9 col-xl-9 col-xxl-9">
        <div class="logo_block">
          <a routerLink="/" target="_blank" class="logo_a"
            ><img src="assets/images/logo.png" alt="" class="img-fluid"
          /></a>
        </div>
        <div class="menu_block navbar navbar-expand-lg">
          <button
            class="navbar-toggler collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i class="fa-solid fa-bars"></i>
            <i class="fa-solid fa-xmark"></i>
          </button>
          <div class="collapse navbar-collapse" id="navbarNavDropdown">
            <ul class="navbar-nav">
              <li
                *ngFor="let link of globals.navLinks"
                class="nav-item"
                [ngClass]="{ active: isCurrentRoute(link, 1) }"
              >
                <a *ngIf="!link.isExternalRoute" [routerLink]="link.route">{{
                  link.label
                }}</a>
                <a *ngIf="link.isExternalRoute" [href]="link.route">{{
                  link.label
                }}</a>
              </li>
              <!--<li class="nav-item"><a (click)="impersonateDialog()">Impersonate</a></li>-->
            </ul>
          </div>
        </div>
      </div>
      <div class="col-3 col-sm-6 col-md-5 col-lg-3 col-xl-3 col-xxl-3">
        <div class="dropdown user_dropdown">
          <button
            class="user_button dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img
              src="assets/images/user-profile.png"
              class="img-fluid"
              alt=""
            />
            <span class="name">{{ globals.authData?.name }}</span>
          </button>
          <ul class="dropdown-menu">
            <li>
              <div class="email_block">
                {{ globals.authData?.name
                }}<span>{{ globals.authData?.email }}</span>
              </div>
            </li>
            <!-- <li>
              <a><i class="fa-regular fa-user"></i>Profile</a>
            </li> -->
            <li>
              <button (click)="logout()">
                <i class="fa-solid fa-arrow-right-from-bracket"></i>Sign Out
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</header>

<!-- Detail Popup-->
<kendo-dialog
  *ngIf="opened"
  (close)="impersonateCloseDialog()"
  [width]="600"
  class="impersonate_block"
>
  <kendo-dialog-titlebar>
    <h3 class="mb-0">Whom do you want to impersonate?</h3>
  </kendo-dialog-titlebar>
  <div>
    <div class="col mb-3">
      <label><span class="red">*</span> Email Address</label>
      <kendo-textbox></kendo-textbox>
    </div>
    <div class="search_warning">
      <div class="alert alert-warning d-flex align-items-center" role="alert">
        <i class="fa-solid fa-triangle-exclamation"></i>
        <div>Here are the details of the email address you entered.</div>
      </div>
      <div class="content">
        <p class="mb-2"><b>Name:</b> Krupali Mistry</p>
        <p class="mb-2"><b>Client:</b> OpenEyes</p>
        <p class="mb-2">
          <b>Address:</b> 1101 Wilson Blvd, Arlington, VA 22209
        </p>
      </div>
    </div>
  </div>
  <kendo-dialog-actions class="sure_block">
    <p class="mt-2 me-2 mb-0">
      Are you sure you want to impersonate this person?
    </p>
    <button kendoButton class="all_btn theme_btn">Yes</button>
    <button kendoButton class="all_btn themeoption_btn">No</button>
  </kendo-dialog-actions>
</kendo-dialog>
